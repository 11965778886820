<template>
  <div>
    <v-dialog
      v-model="isOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{
            isEdit
              ? $t("dues.master_dues.dialog.title_edit")
              : $t("dues.master_dues.dialog.title")
          }}
          <v-spacer></v-spacer>
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-form v-model="valid" ref="formDuesMaster" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('dues.table.dues_name')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="form.frequency"
                  :label="$t('dues.table.frequency')"
                  :rules="[v => !!v || $t('app.required')]"
                  :items="frequencyList"
                  item-text="label"
                  item-value="key"
                  :loading="loadingGrade"
                  :disabled="isEdit"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="form.installments"
                  :label="$t('dues.table.can_be_repaid')"
                  :loading="loadingGrade"
                  :disabled="isEdit"
                  :items="repaidList"
                  item-text="label"
                  item-value="key"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-select
                  v-model="form.status"
                  :label="$t('dues.table.status')"
                  :rules="[v => !!v || $t('app.required')]"
                  :loading="loadingGrade"
                  :disabled="isEdit"
                  :items="statusList"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                >
                </v-select>
              </v-col> -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="form.margin"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('dues.table.payment_tolerance_day')"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <DatePicker
                  :label="$t('dues.table.pay_date')"
                  :rules="[v => !!v || $t('app.required')]"
                  :value="form.billing_date"
                  outlined
                  dense
                  hide-details
                  @getValue="v => (form.billing_date = v)"
                />
              </v-col>
            </v-row>

            <v-row class="my-3" no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loadingSave"
                color="primary"
                depressed
                @click="saveMaster()"
                >{{ $t("app.save") }}</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import { createDuesMaster, editDuesMaster } from "@/api/admin/transactions";

export default {
  props: {
    isOpen: Boolean,
    data: Object,
    close: Function,
    statusList: Array,
    frequencyList: Array,
    repaidList: Array,
    schoolYearId: [String, Number]
  },
  components: { DatePicker },
  data() {
    return {
      valid: false,
      currentDate: moment().format("YYYY-MM-DD"),
      tableHeaders: [
        {
          text: i18n.t("app.school_year"),
          align: "left",
          value: "school_year"
        },
        { text: i18n.t("app.grade"), value: "grade", width: 100 },
        { text: i18n.t("dues.dues_type"), value: "dues_type" },
        {
          text: i18n.t("personalization.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      loading: false,
      loadingSave: false,
      schoolYearList: [],
      gradeList: [],
      duesList: [],
      form: {
        school_year_master: null,
        name: "",
        frequency: "",
        installments: 1,
        billing_date: null,
        margin: "",
        status: 1
      },
      loadingSchoolYear: false,
      loadingGrade: false,
      loadingDues: false,
      isEdit: false
    };
  },
  watch: {
    async isOpen(newVal) {
      if (newVal) {
        this.$refs.formDuesMaster ? this.$refs.formDuesMaster.reset() : "";

        setTimeout(() => {
          this.isEdit = false;

          if (this.data) {
            let data = JSON.parse(JSON.stringify(this.data));
            this.form = {
              id: data.id,
              name: data.name,
              frequency: data.frequency,
              installments: data.installments,
              billing_date: data.billing_date,
              margin: data.margin,
              status: data.status
            };

            this.isEdit = data.id ? true : false;
          } else {
            this.form = {
              name: "",
              frequency: "",
              installments: 1,
              billing_date: null,
              margin: "",
              status: 1
            };
          }
          this.form.school_year_master = this.schoolYearId;
        }, 10);
      }
    }
  },
  methods: {
    saveMaster() {
      if (this.$refs.formDuesMaster.validate()) {
        this.loadingSave = true;
        if (this.isEdit) {
          editDuesMaster(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("app.success_edit"),
                  color: "success"
                });
                this.close("save");
                this.$refs.form.reset();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
          this.loadingSave = false;
        } else {
          createDuesMaster(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("app.success_save"),
                  color: "success"
                });
                this.close("save");
                this.$refs.form.reset();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
        }
      }
    }
  }
};
</script>
