<template>
  <div>
    <v-sheet :class="g_darkmode ? '' : 'white'" class="pa-4">
      <v-row no-gutters align="center">
        <span
          class="d-flex"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'Dues'
            })
          "
        >
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          <h4>{{ $t("dues.master") }}</h4>
        </span>
      </v-row>
      <v-divider class="mt-4 mb-2" />
      <v-row no-gutters class="mb-4" align="center">
        {{ total }} Jenis Tagihan

        <v-spacer />

        <v-select
          v-model="queryData.status"
          :label="$t('dues.table.status')"
          :items="statusList"
          item-text="label"
          item-value="key"
          outlined
          hide-details
          dense
          class="mr-2 select-200 mt-2"
          @change="getDuesMasterHandler"
        />
        <v-btn
          class="gradient-primary mt-2"
          dark
          depressed
          @click="dialogMasterForm = true"
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("dues.master_type") }}
        </v-btn>
      </v-row>

      <v-sheet>
        <v-data-table
          :headers="tableHeaders"
          :items="dataTable"
          :page.sync="queryData.page"
          :items-per-page="queryData.limit"
          :loading="loading"
          hide-default-footer
          disable-pagination
          class="elevation-0"
        >
          <template
            v-if="frequencyList.length > 0"
            v-slot:[`item.frequency`]="{ item }"
          >
            {{ frequencyList.find(s => s.key === item.frequency).label }}
          </template>
          <template
            v-if="repaidList.length > 0"
            v-slot:[`item.installments`]="{ item }"
          >
            {{ repaidList.find(s => s.key === item.installments).label }}
          </template>
          <template v-slot:[`item.billing_date`]="{ item }">
            {{ item.billing_date | dateFormat }}
          </template>
          <template v-slot:[`item.applied_to`]="{ item }">
            <span
              class="primary--text"
              style="cursor: pointer"
              @click="toDuesList(item.id)"
            >
              {{ $t("app.view") }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{
              statusList.length > 0
                ? statusList.find(s => s.key === item.status).label
                : item.status
            }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  icon
                  small
                  class="mr-2 primary"
                  @click="setEdit(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("dues.table.edit") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  icon
                  small
                  class="primary"
                  @click="setDeleteDues(item)"
                >
                  <v-icon small>mdi-minus-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("dues.table.non_active") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />

        <DuesMasterForm
          :isOpen="dialogMasterForm"
          :data="selectedDuesMaster"
          :statusList="statusList"
          :frequencyList="frequencyList"
          :repaidList="repaidList"
          :schoolYearId="g_user.active_schoolyear.school_year_master"
          :close="
            param => {
              dialogMasterForm = false;
              selectedDuesMaster = null;
              if (param) this.getDuesMasterHandler();
            }
          "
        />

        <ModalConfirm
          :title="$t('dues.dialog.confirm_status')"
          :close="() => (this.confirmDelete = false)"
          :loading="loadingDelete"
          :isOpen="confirmDelete"
          :save="deleteMasterDues"
        >
          <span>{{ $t("dues.dialog.confirm_delete") }}</span>
        </ModalConfirm>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("id");

import i18n from "@/i18n";
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import DuesMasterForm from "./DuesMasterForm.vue";
import ModalConfirm from "@/components/ModalConfirm";
import {
  getDuesMasterList,
  getDuesStatus,
  getDuesFrequency,
  setStatusDuesMaster
} from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

let typingTimer;
const doneTypingInterval = 750;

export default {
  filters: {
    priceFormat: value => {
      if (!value) return "-";
      return priceFormat(value);
    },
    dateFormat: value => {
      if (!value) return "-";
      return moment(value).format("DD MMM YYYY");
    }
  },
  components: {
    Pagination,
    DuesMasterForm,
    ModalConfirm
  },
  computed: {
    ...mapGetters(["g_darkmode", "g_user"])
  },
  data() {
    return {
      total: 0,
      length: 0,
      statusList: [],
      frequencyList: [],
      repaidList: [
        { label: i18n.t("app.yes"), key: 1 },
        { label: i18n.t("app.no"), key: 0 }
      ],
      queryData: {
        school_year_master: null,
        status: 1,
        search: "",
        page: 1,
        limit: 10
      },
      loading: false,
      dataTable: [],
      tableHeaders: [
        { text: i18n.t("dues.table.dues_type"), value: "name" },
        { text: i18n.t("dues.table.frequency"), value: "frequency" },
        { text: i18n.t("dues.table.can_be_repaid"), value: "installments" },
        {
          text: i18n.t("dues.table.pay_date"),
          value: "billing_date",
          width: 150
        },
        {
          text: i18n.t("dues.table.payment_tolerance_day"),
          value: "margin",
          width: 150
        },
        {
          text: i18n.t("dues.table.applied_to"),
          value: "applied_to",
          sortable: false
        },
        { text: i18n.t("dues.table.status"), value: "status" },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 100
        }
      ],
      dialogMasterForm: false,
      selectedDuesMaster: null,
      dialogMasterTypeForm: false,
      confirmDelete: false,
      loadingDelete: false,
      tempDelete: {}
    };
  },
  created() {
    getDuesStatus().then(res => {
      res.data.code ? (this.statusList = res.data.data) : "";
    });
    getDuesFrequency().then(res => {
      res.data.code ? (this.frequencyList = res.data.data) : "";
    });
  },
  mounted() {
    this.queryData.school_year_master = this.g_user.active_schoolyear.school_year_master;
    this.getDuesMasterHandler();
  },
  methods: {
    toDuesList(id) {
      console.log(id);
      this.$router.push({ name: "DuesList", params: { id: id } });
    },
    setEdit(dues) {
      this.selectedDuesMaster = dues;
      this.dialogMasterForm = true;
    },
    setAction(param, data) {
      if (param == "master" && data) {
        this.selectedDuesMaster = data;
        this.dialogMasterForm = true;
      } else if (param == "master") {
        this.selectedDuesMaster = {};
        this.dialogMasterForm = true;
      } else {
        this.dialogMasterTypeForm = true;
      }
    },
    getDuesMasterHandler() {
      this.loading = true;
      getDuesMasterList(this.queryData)
        .then(res => {
          const data = res.data.data;
          if (res.data.code) {
            this.dataTable = data.data;
            this.total = data.total;
            this.length = data.last_page;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getDuesMasterHandler();
      }, doneTypingInterval);
    },
    setDeleteDues(item) {
      this.tempDelete = item;
      this.confirmDelete = true;
    },
    deleteMasterDues() {
      this.loadingDelete = true;
      setStatusDuesMaster({
        id: this.tempDelete.id
      })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: i18n.t("app.success_delete"),
              color: "success"
            });

            this.confirmDelete = false;
            this.getDuesMasterHandler();
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingDelete = false;
        })
        .catch(() => (this.loadingDelete = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getDuesMasterHandler();
    }
  }
};
</script>
